export const BLACKLIST_UNSET_FIELDS = ['password', 'password_confirmation'];

// This add the membership price to subtotal in product step for register
export const MEMBERSHIP_APPLIES_IN = {
	default: true,
	apprentice: {
		default: true,
		CA: false,
	},
	promoter: {
		default: false,
	},
	influencer: {
		default: false,
	},
	ambassador: {
		default: false,
	},
};

// Flows defined for a country override the ones defined in defaultCountry,
//    else that flow's limits that are set in defaultCountry are used
//
// Payment methods defined for a flow override the ones defined in the defaultFlow
//    (which will be the one from defaultCountry if the country does not have its own defaultFlow),
//    else that payment method's limits that are set in defaultFlow are used
export const PAYMENT_METHOD_LIMITS = {
	defaultCountry: {
		defaultFlow: {
			sezzle: {
				min: 75,
				max: 2200,
			},
			checkmo: {
				min: 1,
				max: 3000,
			},
			creditcard: {
				min: 9.95,
				max: 3000,
			},
			purchaseorder: {
				min: 9.95,
				max: 3000,
			},
			purchaseorderintl: {
				min: 9.95,
				max: 3000,
			},
			lptunionpay: {
				min: 9.95,
				max: 3000,
			},
			banktransfer: {
				max: 3000,
			},
			gcash: {
				min: 1,
				max: 3000,
			},
			lptpaypal: {
				min: 1,
				max: 3000,
			},
		},
		register: {
			sezzle: {
				min: 75,
				max: 1300,
			},
			creditcard: {
				max: 3000,
			},
			purchaseorder: {
				max: 3000,
			},
			purchaseorderintl: {
				max: 3000,
			},
			lptunionpay: {
				max: 3000,
			},
			checkmo: {
				min: 1,
				max: 3000,
			},
			banktransfer: {
				max: 3000,
			},
			gcash: {
				min: 1,
				max: 3000,
			},
			lptpaypal: {
				min: 1,
				max: 3000,
			},
		},
	},
	MX: {
		defaultFlow: {
			checkmo: {
				min: 1,
				max: 3000,
			},
			oxxopay: {
				max: 500,
			},
			purchaseorderintl: {
				min: 9.95,
				max: 3000,
			},
			banktransfer: {
				max: 3000,
			},
			lptpaypal: {
				min: 1,
				max: 3000,
			},
		},
		becomeDistributor: {
			checkmo: {
				min: 1,
				max: 3000,
			},
			oxxopay: {
				max: 500,
			},
			purchaseorderintl: {
				max: 3000,
			},
			banktransfer: {
				max: 3000,
			},
			lptpaypal: {
				min: 1,
				max: 3000,
			},
		},
		register: {
			checkmo: {
				min: 1,
				max: 3000,
			},
			oxxopay: {
				max: 500,
			},
			purchaseorderintl: {
				max: 3000,
			},
			banktransfer: {
				max: 3000,
			},
			lptpaypal: {
				min: 1,
				max: 3000,
			},
		},
	},
	JP: {
		defaultFlow: {
			checkmo: {
				min: 1,
				max: 3000,
			},
			purchaseorderintl: {
				min: 9.95,
				max: 3000,
			},
			banktransfer: {
				max: 3000,
			},
			lptpaypal: {
				min: 1,
				max: 3000,
			},
		},
		register: {
			checkmo: {
				min: 1,
				max: 3000,
			},
			purchaseorderintl: {
				min: 19.95,
				max: 3000,
			},
			banktransfer: {
				max: 3000,
			},
			lptpaypal: {
				min: 1,
				max: 3000,
			},
		},
	},
};

export const PRODUCT_STEP_HIDDEN_PRODUCTS = {
	becomeDistributor: [],
};

export const CLEAR_ON_EXIT_PAYMENT_METHODS = ['purchaseorder', 'sezzle', 'creditcard', 'oxxopay', 'purchaseorderintl', 'lptpaypal', 'lptunionpay'];

export const SEZZLE_FAQ_URL = 'https://velovita.s3.us-west-1.amazonaws.com/assets/velovita-sezzle-faq.pdf';
export const ORDER_VERIFICATION_PHONES = ['+1 (619) 210-9743', '+1 (352) 317-7505'];

export const SPLIT_PAYMENTS = {
	allowedPaymentMethods: ['purchaseorder', 'purchaseorderintl'],
};

export const LIMIT_OXXO_TRANSFER_DAYS = 5;
export const LIMIT_GCASH_TRANSFER_DAYS = 1;

export const ALLOWED_PAYMENT_METHODS_WITH_WALLET = ['purchaseorder', 'creditcard', 'banktransfer', 'sezzle', 'oxxopay', 'purchaseorderintl', 'lptpaypal', 'lptunionpay'];
export const MIN_AMOUNT_BY_PAYMENT_METHODS_WITH_WALLET = {
	purchaseorder: 25,
	purchaseorderintl: 25,
	creditcard: 25,
	lptunionpay: 25,
	banktransfer: 100,
	sezzle: 75,
	oxxopay: 1,
	lptpaypal: 1,
};
export const PRODUCTS_NOT_ALLOWED_WITH_WALLET = ['TN-DW', 'TN-DB'];

export const CC_PAYMENT_METHOD_BY_COUNTRY = {
	default: 'creditcard',
	US: 'purchaseorder',
	CA: 'purchaseorder',
	MX: 'purchaseorderintl',
	JP: 'purchaseorderintl',
	CN: 'lptunionpay',
};

export const EXCLUDED_CHANGE_PAYMENT_RESTRICTION = ['purchaseorder', 'creditcard', 'purchaseorderintl', 'sezzle', 'lptunionpay'];

export default {};
